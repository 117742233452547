
import * as _ from 'lodash';
import { ActionTypes, AuthStatus } from '../types';

import { createReducer } from '../helpers';

const initialState = {
  authStatus: AuthStatus.Uninitialized,
};


// SELECTORS //

export const Selectors = {
  getAuthStatus: state => state.authStatus,
  getAuthToken: state => _.get(state, 'authInfo.accessToken'),
  getAuthExpiration: state => _.get(state, 'authInfo.expiresAt'),
  getAuthProfile: state => _.get(state, 'authInfo.profile'),
}

// REDUCER //

export default createReducer(
  initialState,
  {
    [ActionTypes.Auth.AuthInfoWasUpdated]: (state, action) => ({
      ...state,
      authInfo: action.authInfo,
    }),
    [ActionTypes.Auth.AuthStatusDidChange]: (state, action) => ({
      ...state,
      authStatus: action.authStatus,      
    }),
  }
);