
import { createReducer } from '../helpers';
import { ActionTypes } from '../types';

const initialState = {
  viewer: null
};


// SELECTORS //

export const Selectors = {
  getViewer: (state) => state.viewer,
};


// REDUCER //

export default createReducer(
  initialState, 
  {
    [ActionTypes.Viewer.ViewerWasUpdated]: (state, action) => ({
      ...state,
      viewer: action.viewer,
    }),
  }
);
