import * as React from 'react';

import { authService } from '../Auth';

export default class LoginPage extends React.Component<{}, {}> {

  componentDidMount() {
    authService.login();
  }

  render() {
    return (
      <div className='containers-App'>
        <h1>Cellflow</h1>
        <div>This is a thing!</div>
      </div>
    );
  }
}
