import { IHostTransactionService, ITransaction } from '../../services/host-services/IHostTransactionService';

export class OfficeTransactionService implements IHostTransactionService {
  
  async addTransaction(transaction: ITransaction): Promise<any> {
    return Excel.run(async (context) => {
      let sheet = context.workbook.worksheets.getActiveWorksheet();

      let rows = [
        [
          transaction.id,
          transaction.transactionDate, 
          transaction.description,
          transaction.amount,
        ]
      ];

      let insertRange = sheet
        .getRange('A1:D1')
        .insert(Excel.InsertShiftDirection.down);

      insertRange.values = rows;

      await context.sync();

      return true;
    });
  }
}