import * as React from 'react';
import { Provider } from '../../services/api/FetchAccounts';
import ProviderHeader from './ProviderHeader';
import AccountDetails from './AccountDetails';


type IProps = {
  provider: Provider;
  onEditProvider: (providerId: string) => void;
  onRemoveProvider: (providerId: string) => void;
};
type IState = {};

export class ProviderItem extends React.PureComponent<IProps, IState> {


  get provider() {
    return this.props.provider;
  }

  private doEditProvider() {
    return this.props.onEditProvider(this.provider.id);
  }

  private doRemoveProvider() {
    return this.props.onRemoveProvider(this.provider.id);
  }

  render() {
    return (
      <div className="ProviderItem">
        <ProviderHeader 
          onEditClicked={() => this.doEditProvider()} 
          onRemoveClicked={() => this.doRemoveProvider()}
          provider={this.provider}>
        </ProviderHeader>
        {this.provider.accounts.map(_ =>
          <AccountDetails account={_}></AccountDetails>
        )}
      </div>      
    );
  }

}

export default ProviderItem;