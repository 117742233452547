import GraphClient from '../GraphClient';

type Dependencies = {
  graph: GraphClient;
}

const GQL =
  `mutation RemoveProvider($providerId: String) {
    removeProvider(providerId: $providerId) {
      status
    }
  }`;

export class RemoveProvider {
  private graph: GraphClient;

  constructor({ graph }: Dependencies) {
    this.graph = graph;
  }

  async removeProvider(providerId: string): Promise<any> {
    let resp = await this.graph.doMutation(GQL, {
      providerId,
    });

    return resp.data.removeProvider.status;
  }

}

export default RemoveProvider;