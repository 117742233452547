import * as React from 'react';
import { Provider } from '../../services/api/FetchAccounts';
import { Colors, Button, Popover, Menu, Position, Intent, Alert } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

type IProps = {
  provider: Provider;
  onEditClicked: () => void;
  onRemoveClicked: () => void;
};
type IState = {
  removeConfirmationIsOpen: boolean;
};

export class ProviderHeader extends React.PureComponent<IProps, IState> {

  state = {
    removeConfirmationIsOpen: false,
  };

  get provider() {
    return this.props.provider;
  }

  onEditClicked() {
    console.log('OpenLink clicked');
    
    this.props.onEditClicked();
  }

  onRemoveClicked() {
    this.setState({
      ...this.state,
      removeConfirmationIsOpen: true
    });
  }

  handleRemoveCancel() {
    this.setState({
      ...this.state, 
      removeConfirmationIsOpen: false
    });
  }

  handleRemoveConfirm() {
    this.props.onRemoveClicked();

    this.setState({
      ...this.state,
      removeConfirmationIsOpen: false
    });
  }

  render() {
    const { removeConfirmationIsOpen } = this.state;

    return (
      <div style={{
        background: Colors.LIGHT_GRAY5,
        padding: 8,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}>

        <Alert
          style={{ width: '80%' }}
          cancelButtonText="Cancel"
          confirmButtonText="Remove accounts"
          icon="trash"
          intent={Intent.DANGER}
          isOpen={removeConfirmationIsOpen}
          onCancel={() => this.handleRemoveCancel()}
          onConfirm={() => this.handleRemoveConfirm()}>
          
          <p>
            Are you sure you want to remove accounts from <b>{this.provider.institution.name}</b>?
          </p>
          <p>
            If you add them again at a later day, you are likely to get duplicate copies 
            of past transactions.
          </p>
        </Alert>

        <div
          style={{
            fontSize: 16
          }}>
          {this.provider.institution.name}
        </div>
        <div>
          <Popover 
            content={
              <Menu>
                <Menu.Item 
                  icon={IconNames.LOG_IN}
                  text="Edit login"
                  onClick={() => this.onEditClicked()}
                />
                <Menu.Divider />
                <Menu.Item 
                  intent={Intent.DANGER}
                  icon={IconNames.TRASH} 
                  text="Remove accounts" 
                  onClick={() => this.onRemoveClicked()}
                />
              </Menu>
            } 
            position={Position.BOTTOM_RIGHT}>

            <Button minimal={true}>Edit</Button>
          </Popover>
        </div>
      </div>
    );
  }

}

export default ProviderHeader;

      // <Pane display="flex"
      //   padding={8}
      //   background="tint2"
      //   borderRadius={0}
      //   alignItems='left'>
      //   <Pane flex={1} alignItems="center" display="flex">
      //     <Text>{this.provider.institution.name}</Text>
      //   </Pane>
      //   <Pane>
      //     <Popover
      //       position={Position.BOTTOM_RIGHT}
      //       content={({close}) =>
      //         <Menu>
      //           <Menu.Item icon="log-in" onSelect={() => this.onEditClicked(close)}>Update login ...</Menu.Item>
      //           <Menu.Divider />
      //           <Menu.Item icon="trash" intent="danger">Delete ...</Menu.Item>
      //         </Menu>
      //       }
      //     >
      //       <Button marginRight={8}>...</Button>
      //     </Popover>

      //   </Pane>
      // </Pane>