
const REQUEST = 'REQUEST';
const SUCCESS = 'SUCCESS';
const FAILURE = 'FAILURE';

type RequestTypes = {
  [REQUEST]: string;
  [SUCCESS]: string;
  [FAILURE]: string;
};

type RequestTypesFn = (string) => RequestTypes;

/**
 * Returns an object containing Async Types.
 * 
 * @param {string} base
 */
export const createRequestTypes: RequestTypesFn = 
  (base) => ({
    [REQUEST]: `${base}_${REQUEST}`,
    [SUCCESS]: `${base}_${SUCCESS}`,
    [FAILURE]: `${base}_${FAILURE}`,
  });


/**
* Creates a reducer that essentially switches on the
* object keys.
* 
* @param initialState 
* @param handlers 
*/
export const createReducer =
  (initialState, handlers) =>
    (state = initialState, action) =>
      handlers.hasOwnProperty(action.type) ? handlers[action.type](state, action) : state;


type ReduxAction = {
  type: string;
  payload?: any;
};

type ActionCreator = (type: string, payload?: any) => ReduxAction;

/**
 * Creates an action.
 * 
 * @param type 
 * @param payload 
 */
export const createAction: ActionCreator = 
  (type, payload = {}) => ({ type, ...payload });
