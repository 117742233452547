import * as React from "react";

import { TFeed } from '../store/reducers/feeds';

interface Props {
  feeds: any[];
}

interface State {
}


const FeedItem = ({feed}: {feed: TFeed}) => {
  let feedSources = feed.sources.map(fs => (
    <div>
      <div>Id: {fs.accountId}</div>
    </div>
  ));

  return (
    <div>
      <h3>Sources</h3>
      {feedSources}
      <h3>Target</h3>
      {feed.target.sheetName}
    </div>
  );
};

export class FeedList extends React.PureComponent<Props, State> {

  render() {
    let { feeds } = this.props;

    let feedComponents = feeds.map(f => <FeedItem feed={f} />);

    return (
      <div className='FeedList'>
        {feedComponents}
      </div>
    );
  }
}


