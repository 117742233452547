import * as React from 'react';

import { connect } from 'react-redux';

import { AuthStatus } from '../store/types';
import { getAuthToken, getAuthStatus, getAuthExpiration } from '../store/reducers';
import Actions from '../store/actions';

interface IProps {
  children: ({}) => any;
  authToken: string;
  authStatus: AuthStatus;
  authExpiration: string;
  doLogout: () => void;
  doRenewToken: () => void;
}
 
const mapStateToProps = state => {
  return {
    authToken: getAuthToken(state), 
    authStatus: getAuthStatus(state),
    authExpiration: getAuthExpiration(state),
  }
}

const mapDispatchToProps = dispatch => {
  return {
    doLogout: () => dispatch(Actions.Auth.logoutWasRequested()),
    doRenewToken: () => dispatch(Actions.Auth.tokenDidExpire()),
  }
};

export const AuthInfoContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(class extends React.Component<IProps, any> {
  shouldComponentUpdate(nextProps) {
    return (this.props.authToken != nextProps.authToken) ||
      (this.props.authStatus != nextProps.authStatus) ||
      (this.props.authExpiration != nextProps.authExpiration);
  }

  render() {
    let { children, ...rest } = this.props;    

    return children(rest);
  }
});

export default AuthInfoContainer;