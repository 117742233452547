import * as React from 'react';
import {
  HashRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';

import UpdateTransactionsDialog from './UpdateTransactionsDialog';

type RouteProps = {
  match: {
    url: string;
  }
}

export default (props: RouteProps) => (
  <Router>
    <Switch>
      <Route path={`${props.match.url}/update_transactions`} component={UpdateTransactionsDialog} />
    </Switch>
  </Router>
);
