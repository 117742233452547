import * as React from 'react';
import {
  HashRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';

// import PrivateRoute from '../components/PrivateRoute';
import ErrorBoundary from '../components/ErrorBoundary';

import { Container, Row, Col } from 'reactstrap';

import './App.scss';

import AuthInfoContainer from '../containers/AuthInfoContainer';

// App interfaces

type RouteProps = {
  match: {
    url: string;
  }
}
type MyProps = {
}

type IProps = MyProps & RouteProps;
type IState = {
  PlansPage: (props: any) => JSX.Element
}

export default class App extends React.Component<IProps, IState> {

  state: IState = {
    PlansPage: (props: any) => <div>Loading ...</div>,
  };

  componentWillMount() {
    import('./PlansPage').then( ({PlansPage}) => {
      this.setState({ PlansPage });
    });
  }

  render() {
    let { PlansPage } = this.state;
    let { match } = this.props;

    return (
      <div className='App'>
        <Container>
          <Row>
            <Col>
              <ErrorBoundary prefix='Routes'>
                <AuthInfoContainer>
                  {({ authStatus, authToken }) =>
                    <Router>
                      <Switch>
                        <Route path={`${match.url}/plans`}>
                            <PlansPage />
                        </Route>
                      </Switch>
                    </Router>
                  }
                </AuthInfoContainer>
              </ErrorBoundary>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
