import Pusher from 'pusher-js';

import { isNil } from 'lodash';
import { take, select, race, fork, put } from 'redux-saga/effects';
import { ActionTypes } from '../types';
import { getAuthToken, getViewer } from '../reducers';

export type Dependencies = {
};

// Dependency Imports //

export default function ({}: Dependencies = {}) {
  return [
    fork(watchForPusherEvents),
  ];
}

//// SAGA FUNCTIONS ////

const EVENTS_TO_TRIGGER_UPDATE = [
  'ProviderWasAdded',
  'ProviderWasRemoved',
  'AccountWasAdded', 
  'AccountWasRemoved'
];

export function* watchForPusherEvents() {
  try {
    let viewer = yield select(getViewer);
    let token  = yield select(getAuthToken);
    let channel = pusherEventChannel({ viewerId: viewer && viewer.id, token });

    while (true) {
      const { channelMessage, authChange } = yield race({
        channelMessage: take(channel),
        authChange: take([ActionTypes.Auth.AuthInfoWasUpdated, ActionTypes.Viewer.ViewerWasUpdated]),
      });

      if (authChange) {
        channel.close();

        viewer = yield select(getViewer);
        token = yield select(getAuthToken);
        
        channel = pusherEventChannel({ viewerId: viewer && viewer.id, token });
      } else if (channelMessage) {
        if (channelMessage.type === 'event' && EVENTS_TO_TRIGGER_UPDATE.includes(channelMessage.eventName)) {
          yield put({ type: ActionTypes.Accounts.AccountListUpdateWasRequested });
        }
        console.log('[WatchToPusherEvents] Pusher Event: ', channelMessage);
      }
    }
  } catch (err) {
    console.error('Unexpected error in watchForPusherEvents: ', err);
  }
}

import { eventChannel } from 'redux-saga'
import Config from '../../Config';

function pusherEventChannel({ viewerId, token }) {
  
  return eventChannel(emitter => {
    if (isNil(viewerId) || isNil(token)) {
      return () => null;
    }

    let pusher = new Pusher(Config().getPusherConfig().key, {
      authEndpoint: `${Config().getApiConfig().baseUri}/pusher/auth`,
      cluster: 'us2',
      auth: {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }
    });

    let channelName = `private-${btoa(viewerId)}`;
    let privateChannel = pusher.subscribe(channelName);
    privateChannel.bind_global((eventName, data) => { 
      emitter({ type: 'event', eventName, data });
    });

    return () => { pusher.unsubscribe(channelName) };
  });
}