import * as React from 'react';
import { 
  Route,
  Redirect,
} from 'react-router-dom';
import { AuthStatus } from '../store/types';
import ErrorBoundary from './ErrorBoundary';
import { trackPageView } from '../Analytics';
import { Colors } from '@blueprintjs/core';

export class PrivateRoute extends React.PureComponent<any, any> {
  constructor(props, context) {
    super(props, context);
  }

  render() {
    let { authStatus, ...rest } = this.props;
    
    switch (authStatus) {
      case AuthStatus.Unauthorized:
        window.localStorage.setItem('Hyperion.AuthRedirect', window.location.href);

        return (<Redirect to={{pathname: '/auth/login'}} />);
      case AuthStatus.Authorized:
        trackPageView(rest.location.pathname);

        return (
          <ErrorBoundary prefix='AuthedRoute'>
            <Route {...rest} />
          </ErrorBoundary>
        );
      default:
        return (
          <div style={{ 
            marginTop: '50%', 
            textAlign: 'center',
            color: Colors.DARK_GRAY1,
            fontSize: 16,
            fontWeight: 'bold',
          }}>
            Loading ...
          </div>
        );
    }
  }

}

export default PrivateRoute;
