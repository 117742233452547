import * as React from 'react';

import * as _ from 'lodash';

import './DeveloperInfoPage.scss';
import { PageBar } from '../components/shell/PageBar';
import { IconNames } from '@blueprintjs/icons';
import { Button, H5, Card, ButtonGroup } from '@blueprintjs/core';
import Api from '../services/Api';
import AuthInfoContainer from '../containers/AuthInfoContainer';
import Modal from 'office-ui-fabric-react/lib/Modal';
import { HostType } from '../hosts/HostType';
import Config from '../Config';

type IProps = {
  authExpiration?: Date;
  onLogoutClicked?: () => void;
  api: Api;
}

type IState = {
  fastlinkDataUrl: string | null;
  fastlinkForm: boolean;
  worksheetId: string | null;
  hostType: HostType | null;
}

export class DeveloperInfo extends React.Component<IProps, IState> {

  constructor(props: IProps) {
    super(props);

    this.state = {
      fastlinkDataUrl: null,
      fastlinkForm: false,
      worksheetId: null,
      hostType: null,
    };
  }
  
  componentWillMount() {
    this.setState({
      ...this.state,
      hostType: Config().hostType,
    })
  }

  handleLaunchFastlinkClicked = async () => {
    this._showFastlink();
  }

  handleLaunchMxConnectClicked = async () => {
    this._showMxConnect();
  }

  _showFastlink = async () => {
    let { api } = this.props;

    const handlePostMessage = (obj) => {
      console.log('handlePostMessage!', obj);
      if (obj.data) {
        if (obj.data.fnToCall === "accountStatus") {
          console.log('accountStatus!');
        }
      }
    };

    window.addEventListener("message", handlePostMessage, false);

    let dataUrl = await api.fetchFastlinkLinkDataUrl();

    this.setState({
      fastlinkForm: true,
      fastlinkDataUrl: dataUrl,
    });
  }

  _showMxConnect = async () => {
    let { api } = this.props;

    let connectUrl = await api.fetchMxConnectUrl();

    window.location.href = connectUrl;
  };

  _closeFastlink = () => {
    this.setState({
      fastlinkForm: false,
      fastlinkDataUrl: null,
    });
  }

  render() {
    let { authExpiration } = this.props;

    let isExpired = !!authExpiration && (authExpiration < new Date()) ? 'YES' : 'NO';

    let hideFastlink = !this.state.fastlinkForm;
    let fastlinkFrameUrl = this.state.fastlinkDataUrl;

    let fastlinkForm = hideFastlink ?
      (<form></form>) :
      (
        <iframe src={fastlinkFrameUrl || ''}
          width='500'
          height='450' />
      );

    return (
      <div className='AppShell-Sidebar DeveloperInfo'>
        <PageBar title='Developer'></PageBar>

        <Card>
          <header>
            <H5>Alternative Aggregators</H5>
          </header>
          <div>
            <ButtonGroup>
              <Button minimal={true} icon={IconNames.LINK} onClick={this.handleLaunchFastlinkClicked}>Fastlink</Button>
              <Button minimal={true} icon={IconNames.LINK} onClick={this.handleLaunchMxConnectClicked}>MX Connect</Button>
            </ButtonGroup>
          </div>
        </Card>
        <Card>
          <header>
            <H5>Host Info</H5>
          </header>

          <dl>
            <dt>Host type</dt>
            <dd>{this.state.hostType}</dd>
          </dl>
        </Card>

        <Card>
          <header>
            <H5>Auth Info</H5>
          </header>

          <dl>
            <dt>Expires At</dt>
            <dd>{authExpiration && authExpiration.toUTCString()}</dd>
            <dt>Expired</dt>
            <dd>{isExpired}</dd>
          </dl>
        </Card>
        <Card>
          <header>
            <H5>Worksheet Info</H5>
          </header>

          <dl>
            <dt>Sheet Id</dt>
            <dd>{this.state.worksheetId || 'none'}</dd>
          </dl>
        </Card>
        <Modal
          isOpen={this.state.fastlinkForm}
          onDismiss={this._closeFastlink}
          isBlocking={false}
          isDarkOverlay={true}
          onLayerDidMount={() => console.log('Mounted!!')}
          containerClassName='fastlinkModel-container'>

          {fastlinkForm}
        </Modal>
      </div>
    );
  }
}

export const DeveloperInfoPage = (props) => (
  <AuthInfoContainer>
    {({ authExpiration }) => (
      <DeveloperInfo authExpiration={authExpiration} {...props} />
    )}
  </AuthInfoContainer>
);

export default DeveloperInfoPage;
