import * as React from 'react';
import * as _ from 'lodash';

import ViewerContainer from '../containers/ViewerContainer';
import { PageBar } from './shell/PageBar';
import { Button } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

import './Dashboard.scss';

type Viewer = {
  id: string;
  profile: {
    name: string;
    email: string;
  }
};

type IProps = {
  viewer: Viewer;
  onLogoutClicked?: () => void;
};
type IState = {};

class Dashboard extends React.Component<IProps, IState> {

  constructor(props: IProps) {
    super(props);
  }

  handleLogoutClicked = () => this.props.onLogoutClicked && this.props.onLogoutClicked();

  componentDidCatch(error: any, info: object) {
    console.error('Error caught: ', error, info);
  }

  render() {
    let { viewer } = this.props;

    let emailAddress = _.get(viewer, 'profile.email', 'Unknown');

    return (
      <div className='AppShell-Sidebar Dashboard'>
        <PageBar title='Dashboard'>
        </PageBar>

        <section>
          <div className='byline'>Welcome, {emailAddress}!</div>
        </section>
        <section>
          <Button minimal={true} icon={IconNames.LOG_OUT} onClick={this.handleLogoutClicked}>Logout</Button>
        </section>
      </div>
    );
  }
}

const WrappedDashboard = (props) => (
  <ViewerContainer>
    { ({viewer}) => (
      <Dashboard viewer={viewer} {...props}/>
    )}
  </ViewerContainer>
);

export default WrappedDashboard;
