

export const LinkEvent: (string, object) => [string, object] | null = (eventName, meta) => {
  if (eventName === 'OPEN') {
    return ['Open', {category: 'Plaid/Link'}];
  } else if (eventName === 'EXIT') {
    return ['Exit', {category: 'Plaid/Link'}];
  } else if (eventName === 'ERROR') {
    return ['LinkError', {category: 'Plaid/Link', label: meta.institution_id, value: meta.error_type}];
  } else if (eventName === 'SEARCH_INSTITUTION') {
    return ['Search', {category: 'Plaid/Link', value: meta.institution_search_query }]
  } else if (eventName === 'SELECT_INSTITUTION') {
    return ['Select', { category: 'Plaid/Link', value: meta.institution_id }]
  } else if (eventName === 'HANDOFF') {
    return ['LinkHandoff', {category: 'Plaid/Link', label: meta.institution_id }];
  }

  return null;
};


// "OPEN"
// error_code: null
// error_message: null
// error_type: null
// exit_status: null
// institution_search_query: null
// link_session_id: "7fed1a81-82c3-4384-bdfb-12db848aef49"
// mfa_type: null
// request_id: null
// timestamp: "2018-06-19T01:49:58.956Z"
// view_name: null

// "SEARCH_INSTITUTION"
// error_code: null
// error_message: null
// error_type: null
// exit_status: null
// institution_search_query: "tarta"
// link_session_id: "dfbe0308-17e0-43f2-a6f9-31945c49f675"
// mfa_type: null
// request_id: null
// timestamp: "2018-06-19T01:50:46.273Z"
// view_name: null

// "SELECT_INSTITUTION"
// error_code: null
// error_message: null
// error_type: null
// exit_status: null
// institution_id: "ins_109511"
// institution_name: "Tartan Bank"
// institution_search_query: null
// link_session_id: "dfbe0308-17e0-43f2-a6f9-31945c49f675"
// mfa_type: null
// request_id: null
// timestamp: "2018-06-19T01:51:07.823Z"
// view_name: null

// "EXIT"
// error_code: ""
// error_message: ""
// error_type: null
// exit_status: "requires_credentials"
// institution_search_query: null
// link_session_id: "d06035b1-f9e7-46a9-be10-8b18e0d4dd9a"
// mfa_type: null
// request_id: "Dj9Ee"
// timestamp: "2018-06-19T01:55:20.065Z"
// view_name: null

// "TRANSITION_VIEW"
// error_code: null
// error_message: null
// error_type: null
// exit_status: null
// institution_id: "ins_109511"
// institution_name: "Tartan Bank"
// institution_search_query: null
// link_session_id: "dfbe0308-17e0-43f2-a6f9-31945c49f675"
// mfa_type: null
// request_id: null
// timestamp: "2018-06-19T01:51:07.841Z"
// view_name: "CREDENTIAL"

// "TRANSITION_VIEW"
// error_code: null
// error_message: null
// error_type: null
// exit_status: null
// institution_id: "ins_109511"
// institution_name: "Tartan Bank"
// institution_search_query: null
// link_session_id: "b41fcece-d2cc-49f1-90a1-24b0af238fb0"
// mfa_type: null
// request_id: null
// timestamp: "2018-06-19T01:52:06.784Z"
// view_name: "LOADING"

// "ERROR"
// error_code: "INVALID_CREDENTIALS"
// error_message: "the provided credentials were not correct"
// error_type: "ITEM_ERROR"
// exit_status: null
// institution_id: "ins_109511"
// institution_name: "Tartan Bank"
// institution_search_query: null
// link_session_id: "b369a903-784c-4ea6-9ea5-8aa8591f2cea"
// mfa_type: null
// request_id: null
// timestamp: "2018-06-19T01:53:12.227Z"
// view_name: null

// "TRANSITION_VIEW"
// error_code: null
// error_message: null
// error_type: null
// exit_status: null
// institution_id: "ins_109511"
// institution_name: "Tartan Bank"
// institution_search_query: null
// link_session_id: "d06035b1-f9e7-46a9-be10-8b18e0d4dd9a"
// mfa_type: null
// request_id: "Dj9Ee"
// timestamp: "2018-06-19T01:54:36.147Z"
// view_name: "ERROR"



// [Log] link: user event – "TRANSITION_VIEW"(app.js, line 50244)
// Object

// error_code: null

// error_message: null

// error_type: null

// exit_status: null

// institution_id: "ins_109511"

// institution_name: "Tartan Bank"

// institution_search_query: null

// link_session_id: "d06035b1-f9e7-46a9-be10-8b18e0d4dd9a"

// mfa_type: null

// request_id: "36iSb"

// timestamp: "2018-06-19T02:11:23.439Z"

// view_name: "SELECT_ACCOUNT"

// Object Prototype


// ----


//   [Log] link: user event – "HANDOFF"(app.js, line 50244)
// Object

// error_code: null

// error_message: null

// error_type: null

// exit_status: null

// institution_id: "ins_109511"

// institution_name: "Tartan Bank"

// institution_search_query: null

// link_session_id: "d06035b1-f9e7-46a9-be10-8b18e0d4dd9a"

// mfa_type: null

// request_id: "36iSb"

// timestamp: "2018-06-19T02:11:41.890Z"

// view_name: null

// Object Prototype


