import * as React from 'react';

interface IProps {
  prefix: string;
}

interface IState {
  hasError: boolean;
}

export default class ErrorBoundary extends React.PureComponent<IProps, IState> {
  constructor(props) {
    super(props);

    this.state = { hasError: false };
  }

  get prefix() {
    return this.props.prefix || 'Global';
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ hasError: true });
    // You can also log the error to an error reporting service
    console.log('[' + this.prefix  + '] Component caught error: ', error, info);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <h1>Something went wrong.</h1>;
    }
    return this.props.children;
  }
}
