

import { ActionTypes } from '../types';

import { createReducer } from '../helpers';

const initialState = {
  accountList: [],
};


// SELECTORS //

export const Selectors = {
  getAccountList: state => state.accountList,
}

// REDUCER //

export default createReducer(
  initialState,
  {
    [ActionTypes.Accounts.AccountWasAdded]: (state, action) => {
      
      let accountList = Array.from(state.accountList);

      let indexOfExisting = accountList.findIndex(a => (a as any).id === action.account.id);
      
      if (indexOfExisting > 0) {
        accountList[indexOfExisting] = action.account;
      } else {
        accountList = accountList.concat([action.account]);
      }

      return {
        ...state,
        accountList: accountList,
      };
    },

    [ActionTypes.Accounts.AccountListWasUpdated]: (state, action) => {
      return {
        ...state,
        accountList: action.accountList,
      };
    },

  }
);