
import Config from '../Config';

import { HostType } from '../hosts/HostType';
import { OfficeTransactionService } from '../hosts/office/OfficeTransactionService';
import { GSuiteTransactionService } from '../hosts/gsuite/GSuiteTransactionService';
import { WebTransactionService } from '../hosts/web/WebTransactionService';

export const transactionService = () => {
  let config = Config();

  return config.hostType == HostType.GSUITE ? new GSuiteTransactionService() :
         config.hostType == HostType.OFFICE ? new OfficeTransactionService() :
         new WebTransactionService();
};