import Api from '../Api';

export default class RegisterUserService {

  private api: Api;

  constructor({ api }: { api: Api }) {
    this.api = api;
  }


  async registerUser() {
    let { apiBase, token } = this.api;

    let opts = {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`,
      }
    };

    let response = await fetch(
      `${apiBase}/u/register`,
      opts
    );

    let responseBody = await response.json();

    return { 
      status: responseBody.status,
    };
  }
}