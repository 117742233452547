// import * as React from 'react';

import { connect } from 'react-redux';
// import { isEqual } from 'lodash';

import { getFeedList } from '../store/reducers';

// interface IProps {
//   children: ({ }) => any;
//   accountList: any[];
// }

const stateToProps = state => {
  return {
    feedList: getFeedList(state),
  }
}

const dispatchToProps = dispatch => ({

});

export default connect(stateToProps, dispatchToProps)(({children, ...rest}) => children(rest));
