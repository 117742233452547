
import { ActionTypes, AuthStatus } from '../types';

import { createAction } from '../helpers';

export namespace Accounts {
  export const accountWasAdded = (account: any) => createAction(ActionTypes.Accounts.AccountWasAdded, { account });
  export const accountListWasUpdated = (accountList: any) => createAction(ActionTypes.Accounts.AccountListWasUpdated, { accountList });
  export const removeProviderWasRequested = (providerId: string) => createAction(ActionTypes.Accounts.ProviderRemovalWasRequested, { providerId });
}

export namespace Application {
  export const applicationDidMount = () => createAction(ActionTypes.Application.ApplicationDidMount);
  export const applicationDidInitialize = () => createAction(ActionTypes.Application.ApplicationDidInitialize);
}

export namespace Auth {
  export const authInfoWasUpdated = (authInfo: any) => createAction(ActionTypes.Auth.AuthInfoWasUpdated, {
    authInfo,
  });
  export const logoutWasRequested = () => createAction(ActionTypes.Auth.LogoutWasRequested);
  export const tokenDidExpire = () => createAction(ActionTypes.Auth.TokenDidExpire);
  export const authStatusDidChange = (authStatus: AuthStatus) => 
    createAction(ActionTypes.Auth.AuthStatusDidChange, { 
      authStatus 
    });
  export const tokenSubDidChange = (sub: string) => 
    createAction(ActionTypes.Auth.TokenSubDidChange, { sub });
  export const tokenExpirationDidChange = (expiresAt: Date) =>
    createAction(ActionTypes.Auth.TokenExpirationDidChange, { expiresAt });
}

export namespace Viewer {
  export const viewerWasUpdated = (viewer: any) => 
    createAction(ActionTypes.Viewer.ViewerWasUpdated, { viewer });
}

export namespace ProviderLinks {
  export const linkPublicTokenWasCreated = (publicToken: string, { accounts }) => 
    createAction(ActionTypes.ProviderLinks.LinkPublicTokenWasCreated, { publicToken, accounts });

  export const openLinkWasRequested = (providerId?: string) => {
    if (providerId) {
      return createAction(ActionTypes.ProviderLinks.OpenLinkWasRequested, { providerId });
    } else {
      return createAction(ActionTypes.ProviderLinks.OpenLinkWasRequested);
    }
  };
}


export default {
  Accounts,
  Application,
  Auth,
  Viewer,
  ProviderLinks,
};
