
import Config from './Config';
let config = Config();

import { HostType } from './hosts/HostType';
import * as GSuiteAuth from './hosts/gsuite/AuthImpl';
import * as OfficeAuth from './hosts/office/AuthImpl';

const AuthImpls: { AuthService, AuthStorage } = 
  config.hostType === HostType.GSUITE ? GSuiteAuth : OfficeAuth;

export const authService = new AuthImpls.AuthService(config.getAuthConfig());
export const authStorage = new AuthImpls.AuthStorage();
