
export namespace ActionTypes {
  export namespace Accounts {
    export const AccountListUpdateWasRequested = 'AccountList/UpdateWasRequested';
    export const AccountWasAdded = 'Accounts/AccountWasAdded';
    export const AccountListWasUpdated = 'AccountList/WasUpdated';
    export const ProviderRemovalWasRequested = 'Provider/RemovalWasRequested';
  }

  export namespace Application {
    export const ApplicationDidMount = 'Application/ApplicationDidMount';
    export const ApplicationDidInitialize = 'Application/ApplicationDidInitialize';
  }

  export namespace Auth {
    export const AuthInfoWasUpdated = 'Auth/AuthInfoWasUpdated';
    export const LogoutWasRequested = 'Auth/LogoutWasRequested';
    export const TokenDidExpire = 'Auth/TokenDidExpire';
    export const AuthStatusDidChange = 'Auth/AuthStatusDidChange';
    export const TokenSubDidChange = 'Auth/TokenSubDidChange';
    export const TokenExpirationDidChange = 'Auth/TokenExpirationDidChange';
  }

  export namespace Viewer {
    export const ViewerWasUpdated = 'Viewer/ViewerWasUpdated';
  }

  export namespace ProviderLinks {
    export const LinkPublicTokenWasCreated = 'ProviderLinks/LinkPublicTokenWasCreated';
    export const OpenLinkWasRequested = 'ProviderLinks/OpenLinkWasRequested';
  }
}

export enum AuthStatus {
  Uninitialized = 'Uninitialized',
  Authorizing = 'Authorizing',
  Renewing = 'Renewing',
  Unauthorized = 'Unauthorized',
  Authorized = 'Authorized',
};
