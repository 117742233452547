import * as React from "react";

import { isEmpty } from 'lodash';

// import { Callout, DirectionalHint } from 'office-ui-fabric-react/lib/Callout';
import { TeachingBubble } from 'office-ui-fabric-react/lib/TeachingBubble';
import { IButtonProps } from 'office-ui-fabric-react/lib/Button';
import { MessageBar } from 'office-ui-fabric-react/lib/MessageBar';

import './AccountList.scss';
import { DirectionalHint } from 'office-ui-fabric-react/lib/Callout';
import { Provider } from '../services/api/FetchAccounts';
import ProviderItem from './account-list/ProviderItem';

type Props = {
  htmlProps?: React.HTMLAttributes<HTMLDivElement>;
  accounts: Provider[];
  onAddAccountsClicked: () => any;
  onEditProvider: (providerId: string) => void;
  onRemoveProvider: (providerId: string) => void;
}

interface State {
  isCalloutVisible: boolean;
}

const EmptyList = ({target, onAddAccountsClicked, onDismiss, isCalloutVisible}) => {

  const primaryButtonProps: IButtonProps = {
    children: 'Try it out',
    onClick: () => {
      onDismiss();
      onAddAccountsClicked(); 
    }
  };

  const secondaryButtonProps: IButtonProps = {
    children: 'Maybe later',
    onClick: onDismiss
  };


  return (
    <div>
      <MessageBar>
        No accounts added.
      </MessageBar>

      {isCalloutVisible ? (
        <TeachingBubble
          targetElement={target}
          onDismiss={onDismiss}
          hasSmallHeadline={true}
          primaryButtonProps={primaryButtonProps}
          secondaryButtonProps={secondaryButtonProps}
          calloutProps={{
            directionalHint: DirectionalHint.bottomAutoEdge,
            gapSpace: 8,
          }}
          hasCloseIcon={true}
          headline='Add financial accounts'>

          Add financial accounts to begin importing transactions.

      </TeachingBubble>
      ) : null}
    </div>
  );

};

export class AccountList extends React.PureComponent<Props, State> {
  
  state = { isCalloutVisible: false };

  componentDidMount() {
    window.setTimeout(() => this.setState({ isCalloutVisible: true }), 1500); 
  }

  get providers() {
    return this.props.accounts;
  }

  render() {
    let { isCalloutVisible } = this.state;

    let target = '.ms-Button[name="Add"]';
    let onDismiss = () => this.setState({
      isCalloutVisible: false
    });
    let { onAddAccountsClicked } = this.props;

    return (
      <div className='AccountList' {...this.props.htmlProps}>
        { this.hasAccounts ? (
          this.providers.map(_ => 
            <ProviderItem 
              onEditProvider={(id) => this.props.onEditProvider(id)} 
              onRemoveProvider={(id) => this.props.onRemoveProvider(id)}
              provider={_}>
            </ProviderItem>)
        ) : (
          EmptyList({target, onDismiss, onAddAccountsClicked, isCalloutVisible})
        ) }
        
      </div>
    );
  }

  private get hasAccounts() {
    console.log('hasAccounts :: ', !isEmpty(this.props.accounts));
    return !isEmpty(this.props.accounts);

  }

  // private onClick(accountId) {
  //   console.log('Clicked!! ', accountId);
  //   this.props.onEditAccount(accountId);
  // }
}


