// /* eslint-disable no-constant-condition */
import { take, put, fork, all, call } from 'redux-saga/effects';
import Actions from '../actions';
import { ActionTypes } from '../types';

import AccountsSagas from './accounts';
import AuthSagas, { Dependencies as AuthDependencies } from './auth';
import PLSagas, { Dependencies as PLDependencies } from './provider-links';
import PusherSagas from './pusher';

type MyDependencies = {
  retrieveAuthInfo?: () => Promise<any>;
};

export type Dependencies = MyDependencies & AuthDependencies & PLDependencies;

import { authStorage } from '../../Auth';
import { IAuthInfo } from '../../auth/IAuthInfo';

export default function ({ 
  retrieveAuthInfo = async () => authStorage.retrieveAuthInfo(), 
  ...authDeps 
}: Dependencies) {

  return function* () {
    yield all([
      fork(watchForMountAndInitializeApplication, { retrieveAuthInfo }),
      ...AccountsSagas(),
      ...AuthSagas(authDeps),
      ...PLSagas(),
      ...PusherSagas(),
    ]);
  };
}

//// SAGA FUNCTIONS ////

/**
 * Initialize application on application mount event.
 * 
 * Only executes ONCE.
 */
export function* watchForMountAndInitializeApplication({ retrieveAuthInfo }: { retrieveAuthInfo: () => Promise<IAuthInfo | null> }) {
  yield take(ActionTypes.Application.ApplicationDidMount);

  console.log('watchForMountAndInitializeApplication -> get Auth Info: ');
  let authInfo = yield call(retrieveAuthInfo);
  console.log('watchForMountAndInitializeApplication -> retrieved Auth Info: ', authInfo);

  yield put(Actions.Auth.authInfoWasUpdated(authInfo));
  // yield put(Actions.Auth.authInfoWasUpdated(null));

  yield put(Actions.Application.applicationDidInitialize());
  // yield put(Actions.Auth.tokenDidExpire());
}
