import * as React from 'react';


type Props = {
};

export default class UpdateTransactionsDialog extends React.Component<Props, {}> {

  componentWillMount() {
    (window as any).$crisp.push(["do", "chat:hide"]);
  }

  render() {
    return (
      <div className='dialogs-UpdateTransactionsDialog'>
        <h1>Update transactions ...</h1>
      </div>
    );
  }
}
