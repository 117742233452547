import GraphClient from '../GraphClient';
import { get } from 'lodash/fp';

type Dependencies = {
  graph: GraphClient;
}

const GQL =
  `query AccountsQuery {
    viewer {
      ...on User {
        providers {
          id
          institution {
            name
          }
          accounts {
            id
            name {
              short
              long
            }
            currentBalance {
              amount
              currency
            }
          }
        }
      }
    }
  }`;

const FieldGetter = get('viewer.providers');

export type Account = {
  id: string;
  name: {
    short: string;
    long: string;
  };
  currentBalance?: {
    amount: number;
    currency: string;
  };
};

export type Provider = {
  id: string;
  institution: {
    name: string;
  };
  accounts: Account[];
};

export default class FetchAccounts {

  private graph: GraphClient;

  constructor({ graph }: Dependencies) {
    this.graph = graph;
  }

  async fetch(): Promise<Provider[]> {
    let resp = await this.graph.doQuery(GQL);

    let providers = FieldGetter(resp.data);

    console.log('FetchAccounts -> providers :: ', providers);
    console.log('FetchAccounts -> resp :: ', resp);

    return FieldGetter(resp.data);
  }

}