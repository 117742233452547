
import Config from '../Config';

export default class Api {

  token: string | null;
  apiBase: string;

  constructor({token = null}: {token?: string | null}) {
    let baseUri = Config().getApiConfig().baseUri;

    this.apiBase = baseUri;
    this.token = token;
  }

  async fetchFastlinkLinkDataUrl() {
    let opts = {
      method: 'GET',
      headers: {
        'Accept': 'text/html',
        'Authorization': `Bearer ${this.token}`,
      }
    };

    let response = await fetch(
      `${this.apiBase}/link/yodlee/token`,
      opts
    );

    let blob = await response.blob();

    // console.log('blob == ', blob);

    return URL.createObjectURL(blob);
  }

  async fetchMxConnectUrl() {
    let opts = {
      method: 'GET',
      headers: {
        'Accept': 'text/html',
        'Authorization': `Bearer ${this.token}`,
      }
    };

    let response = await fetch(
      `${this.apiBase}/link/atrium/connect`,
      opts
    );

    let result = await response.json();

    return result.url;
  }

}