import * as React from 'react';


import FeedListContainer from '../containers/FeedListContainer';
import { FeedList } from '../components/FeedList';
import { CommandBar } from 'office-ui-fabric-react/lib/CommandBar';

type Props = {
};

export default class FeedsPage extends React.Component<Props, {}> {

  items() {
    return [
      {
        key: 'addAccount',
        name: 'Add',
        cacheKey: 'myCacheKey',
        iconProps: {
          iconName: 'Add'
        },
        ariaLabel: 'Add. Use left and right arrow keys to navigate',
        ['data-automation-id']: 'addAccountMenu',
        onClick: () => true
      },
      {
        key: 'edit',
        name: 'Edit',
        iconProps: {
          iconName: 'Edit'
        },
        onClick: () => true
      }
    ];
  }

  render() {
    return (
      <div className='pages-FeedsPage'>
        <CommandBar items={this.items()} />
        <section>
          <FeedListContainer>
            {({ feedList }) => (
              <FeedList feeds={feedList} />
            )}
          </FeedListContainer>
        </section>
      </div>
    );
  }
}
