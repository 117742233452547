import { IHostTransactionService, ITransaction } from '../../services/host-services/IHostTransactionService';
import { AppToaster } from '../../containers/AppToaster';
import { Intent } from '@blueprintjs/core';

export class WebTransactionService implements IHostTransactionService {

  async addTransaction(transaction: ITransaction): Promise<any> {
    AppToaster.show({ 
      message: `HOST: Transaction added: ${transaction.description} - ${transaction.amount}`, 
      intent: Intent.SUCCESS 
    });
  }
}