import * as React from 'react';
import { Account } from '../../services/api/FetchAccounts';

import * as accounting from 'accounting';

type IProps = {
  account: Account;
};
type IState = {};

type Amount = {
  amount: number;
  currency: string;
};

export class AccountDetails extends React.PureComponent<IProps, IState> {


  get account() {
    return this.props.account;
  }

  private asCurrency(amount: Amount | undefined) {
    if (!amount) return null;

    return accounting.formatColumn([amount.amount]);
  }

  render() {
    return (
      <div className='AccountDetails'>
        <div className='name'>{this.account.name.short}</div>

        <div className='currentBalance'>{this.asCurrency(this.account.currentBalance)}</div> 
      </div>
    );
  }

}

export default AccountDetails;