
import Config from '../Config';

// TODO(bls): This should ultimately pull from some configuration.
//
const endpointFromEnvironment = () => Config().getApiConfig().baseUri + '/graphql';

class GraphClient {

  private endpoint: string;
  private token: string | null;

  constructor({endpoint = null, token = null}: { endpoint?: string | null; token?: string | null} = {}) {
    this.endpoint = endpoint || endpointFromEnvironment();
    this.token = token;
  }

  doQuery = async (query, variables: any = undefined) => {
    let opts = {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': `Bearer ${this.token}`,
      },
      body: JSON.stringify({ query, variables })
    };

    let response = await fetch(
      `${this.endpoint}`,
      opts
    );

    return await response.json();
  }

  doMutation = async (mutation, variables = {}) => {
    let opts = {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': `Bearer ${this.token}`,
      },
      body: JSON.stringify({ query: mutation, variables })
    };

    let response = await fetch(
      `${this.endpoint}`,
      opts
    );

    return await response.json();
  }
}

export default GraphClient;