import * as React from 'react';
import * as ReactDOM from 'react-dom';

import App from './components/App';
import { Provider } from 'react-redux';

import './styles/styles.scss';

import { initializeIcons } from 'office-ui-fabric-react/lib/Icons';

import configureStore from './store';

let store = configureStore({}, {});

initializeIcons();

import AuthInfoContainer from './containers/AuthInfoContainer';

import Actions from './store/actions';
import { isOfficeEnvironment } from './office/Host';

import ReactLoading from 'react-loading';

ReactDOM.render(
  <div>
    <ReactLoading type='bars'></ReactLoading>
  </div>,
  document.getElementById('container')
);

(() => {
  const container = document.getElementById('container');

  (window as any).store = store;

  if (isOfficeEnvironment()) {
    Office.onReady(function () {
      console.log('Is In Office? ', isOfficeEnvironment());

      ReactDOM.render(
        <Provider store={store}>
          <AuthInfoContainer>
            {({ authToken, authStatus }) => {
              return (
                <App onApplicationMount={() => store.dispatch(Actions.Application.applicationDidMount())} />
              );
            }}
          </AuthInfoContainer>
        </Provider>,
        container
      );
    });
  } else {
    ReactDOM.render(
      <Provider store={store}>
        <AuthInfoContainer>
          {({ authToken, authStatus }) => {
            return (
              <App onApplicationMount={() => store.dispatch(Actions.Application.applicationDidMount())} />
            );
          }}
        </AuthInfoContainer>
      </Provider>,
      container
    );
  }
})();


