import * as React from 'react';

import { connect } from 'react-redux';

import { IHostTransactionService } from '../services/host-services/IHostTransactionService';
import { transactionService } from '../config/host-services';

interface IProps {
  children: ({ }) => any;
  transactionService: IHostTransactionService;
}

const stateToProps = state => ({});

const dispatchToProps = dispatch => ({
  transactionService: transactionService(),
});

class Container extends React.Component<IProps, any> {
  render() {
    let { children, ...rest } = this.props;

    return children(rest);
  }
}

export default connect(stateToProps, dispatchToProps)(Container);
