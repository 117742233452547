import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Navbar } from '@blueprintjs/core';

type IProps = {
  title: string;
  pageBarElementId?: string;
};

type IState = {
  pageBarElement?: Element;
};

export class PageBar extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentWillMount() {
    this.setState({
      pageBarElement: document.getElementById(this.props.pageBarElementId || 'pageBarContainer') || undefined,
    });
  }

  render() {
    if (this.state.pageBarElement) {
      return ReactDOM.createPortal(
        [
          <Navbar.Heading style={{ marginLeft: 12, width: 120 }}>{this.props.title}</Navbar.Heading>,
          this.props.children
        ],
        this.state.pageBarElement,
      );
    } else {
      return null;
    }
  }
}
