
import { take, select, call, put, takeEvery, fork, takeLatest } from 'redux-saga/effects';
import { ActionTypes, AuthStatus } from '../types';
import { Viewer as ViewerActions, Auth as AuthActions } from '../actions';
import { getAuthToken, getAuthExpiration, getAuthProfile, getAuthStatus } from '../reducers';
import Actions from '../actions';
import * as _ from 'lodash';

export type AuthToken = string;

export type RenewTokenAction = () => Promise<IAuthInfo>;
export type LogoutAction = () => void;
export type PersistAuthInfoAction = (info: IAuthInfo | null) => Promise<void>;
export type ClearAuthInfoAction = () => Promise<void>;
export type FetchViewerAction = (opts: { token: AuthToken }) => Promise<any>;
export type RegisterUserAction = (opts: { token: AuthToken }) => Promise<any>;

export type Dependencies = {
  persistAuthInfo?: PersistAuthInfoAction;
  clearAuthInfo?: ClearAuthInfoAction;
  renewToken?: RenewTokenAction;
  doLogout?: LogoutAction;
  fetchViewer?: FetchViewerAction;
  registerUser?: RegisterUserAction;
};

// Dependency Imports //

import { authStorage, authService } from '../../Auth';

import GraphClient from '../../services/GraphClient';
import FetchViewer from '../../services/api/FetchViewer';
import RegisterUserService from '../../services/api/RegisterUserService';
import Api from '../../services/Api';

export default function ({
  persistAuthInfo = async (authInfo) => authStorage.persistAuthInfo(authInfo),
  clearAuthInfo = async () => authStorage.clearAuthInfo(),
  renewToken = () => authService.renewToken(),
  doLogout = () => authService.logout(),
  fetchViewer = async ({token}) => 
    new FetchViewer({ graph: new GraphClient({token}) }).fetch(),

  registerUser = async ({token}) =>
    new RegisterUserService({ api: new Api({token}) }).registerUser(),

}: Dependencies = {}) {
  return [
    // takeEvery([ActionTypes.Viewer.ViewerWasUpdated], updateAuthStatusWhenViewerChanged),
    fork(trackAuthInfoChanges),
    takeEvery(
      ActionTypes.Auth.TokenExpirationDidChange, 
      manageAuthTokenRenewalTimer
    ),
    takeLatest(
      [
        ActionTypes.Auth.TokenSubDidChange,
        ActionTypes.Auth.TokenExpirationDidChange,
      ],
      authorizationFlow, { fetchViewer, registerUser }),
    fork(watchForLogoutWasRequested, { clearAuthInfo, doLogout }),
    fork(renewTokenOnExpiration, { renewToken, /* persistAuthInfo */ }),
  ];
}

//// SAGA FUNCTIONS ////


export function* trackAuthInfoChanges() {
  while (true) {
    const previousExpiration = yield select(getAuthExpiration);
    const previousProfile = yield select(getAuthProfile);
    yield take(ActionTypes.Auth.AuthInfoWasUpdated);
    const currentExpiration = yield select(getAuthExpiration);
    const currentProfile = yield select(getAuthProfile);

    let authStatus = yield select(getAuthStatus);

    if ( _.invoke(previousExpiration, 'getTime') !== _.invoke(currentExpiration, 'getTime') ) {
      yield put(AuthActions.tokenExpirationDidChange(currentExpiration));
    }

    if (authStatus === AuthStatus.Uninitialized || (_.get(previousProfile, 'sub') !== _.get(currentProfile, 'sub'))) {
      yield put(AuthActions.tokenSubDidChange(_.get(currentProfile, 'sub')));
    }
  }
}


import { eventChannel } from 'redux-saga'
import { IAuthInfo } from '../../auth/IAuthInfo';

/**
 * Creates an event channel that is triggered when the
 * provided expiration date occurs.
 * 
 * Timer fires 5 seconds before actual expiration.
 * 
 * @param expirationDate 
 */
const createExpirationChannel = (expirationDate) => {
  return eventChannel(emitter => {
    const iv = setTimeout(() => { emitter(expirationDate) }, (expirationDate.valueOf() - new Date().valueOf() - 5000));

    return () => {
      clearInterval(iv)
    }
  });
}

/**
 * Sets a timer to renew 
 */
export function* manageAuthTokenRenewalTimer() {
  let expiration = yield select(getAuthExpiration);

  if (expiration) {
    const now = new Date();
    
    if (expiration <= now) {
      yield put(Actions.Auth.tokenDidExpire());
      return;
    }

    let chan = yield call(createExpirationChannel, expiration);
    yield take(chan);

    let newExpiration = yield select(getAuthExpiration);
    if (expiration === newExpiration) {
      yield put(Actions.Auth.tokenDidExpire());
    }
  }
}

export function* authorizationFlow({ fetchViewer, registerUser }) {
  try {
    yield put(Actions.Auth.authStatusDidChange(AuthStatus.Authorizing));

    // 1. If no authToken, user is UNAUTH'd. Else, continue ...
    let authToken = yield select(getAuthToken);

    if (!authToken) {
      // console.log('[AuthorizationFlow] No auth token.');

      yield put(Actions.Viewer.viewerWasUpdated(null));
      yield put(Actions.Auth.tokenDidExpire());
      return;
    }

    // 2. If authToken is expired, trigger renewal and bail.
    //    Renewal flow will fire appropriate events to start this over again.
    //
    let authExpiration = yield select(getAuthExpiration);

    if (authExpiration < new Date()) {
      console.log('[AuthorizationFlow] Token expired.');

      return;
    }

    // 3. Get viewer information from API; if none exists, register user.
    //
    let viewer = yield call(fetchViewer, { token: authToken });

    if (!viewer) {
      console.log('[AuthorizationFlow] No viewer, registering user ...');

      // new user flow
      let registrationResp = yield call(registerUser, { token: authToken });

      if (registrationResp.status === 'ok') {
        viewer = yield call(fetchViewer, { token: authToken });
      } else {
        console.error('[AuthorizationFlow] Unexpected issue registering user: ', registrationResp);
        yield put(Actions.Auth.authStatusDidChange(AuthStatus.Unauthorized));
        
        return;
      }
    }

    // Somewhere in here we'll need to trigger an onboarding flow.

    yield put(ViewerActions.viewerWasUpdated(viewer));
    yield put(Actions.Auth.authStatusDidChange(AuthStatus.Authorized));
  } catch (err) {
    console.error('[AuthorizationFlow] unexpected error: ', err);
    yield put(Actions.Auth.authStatusDidChange(AuthStatus.Unauthorized));
  }
}

// export function* updateViewerOnAuthInfoChange({ }) {
//   try {
//     let authToken = yield select(getAuthToken);
//     let graph = new GraphClient({ token: authToken });
//     let fetcher = new FetchViewer({ graph });

//     let viewer = yield call([fetcher, fetcher.fetch]);

//     yield put(ViewerActions.viewerWasUpdated(viewer));
//   } catch (err) {
//     console.error('[updateViewerOnAuthInfoChange] unexpected error: ', err);
//   }
// }

/**
 * Updates AuthStatus
 */
// export function* updateAuthStatusWhenViewerChanged() {
//   let viewer = yield select(getViewer);

//   let status = viewer ? AuthStatus.Authorized : AuthStatus.Unauthorized;

//   yield put(Actions.Auth.authStatusDidChange(status));
// }

/**
 * Watches for Logout Requests and execute logout process. 
 */
export function* watchForLogoutWasRequested({ clearAuthInfo, doLogout }: { clearAuthInfo: () => any, doLogout: LogoutAction }) {
  while (true) {
    yield take(ActionTypes.Auth.LogoutWasRequested);

    doLogout();
  }
}

type RenewTokensOnExpirationDeps = {
  renewToken: RenewTokenAction,
  // persistAuthInfo: PersistAuthInfoAction
};

/**
 * Listens for token expiration events and attempts to renew tokens.
 */
export function* renewTokenOnExpiration({ 
  renewToken, 
  // persistAuthInfo 
}: RenewTokensOnExpirationDeps) {
  while (true) {
    yield take(ActionTypes.Auth.TokenDidExpire);
    
    let authStatus = yield select(getAuthStatus);
    if (authStatus === AuthStatus.Renewing)
      return;

    yield put(Actions.Auth.authStatusDidChange(AuthStatus.Renewing));

    console.info('Renew Token on Expiration');

    try {
      let authInfo = yield call(renewToken);

      // yield call(persistAuthInfo, authInfo);
      yield put(Actions.Auth.authInfoWasUpdated(authInfo));
      yield put(Actions.Auth.authStatusDidChange(AuthStatus.Authorizing));
    } catch (err) {
      // yield call(persistAuthInfo, null);
      yield put(Actions.Auth.authInfoWasUpdated(null));
      yield put(Actions.Auth.authStatusDidChange(AuthStatus.Unauthorized));
    }
  }
}