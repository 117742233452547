
import { take, select, call, fork, put } from 'redux-saga/effects';
import { ActionTypes } from '../types';
// @ts-ignore
import { getGraphClient, getAuthToken } from '../reducers';
import Actions from '../actions';

export type Dependencies = {
  // addProviderLink?: (publicToken: string) => Promise<any>;
};

// @ts-ignore
const PublicTokenFetcher =
  async ({ token, providerId }) => new FetchPublicToken({ graph: new GraphClient({ token }) }).fetchToken(providerId);

// Dependency Imports //

import AddProviderLinkService from '../../services/api/plaid/AddProviderLinkService';
import LinkService, { PlaidEnvironment, PlaidService } from '../../plaid/LinkService';
import FetchPublicToken from '../../services/api/plaid/FetchPublicToken';
import GraphClient from '../../services/GraphClient';


export default function ({}: Dependencies = {}) {
  let linkChannel = linkServiceChannel();

  return [
    fork(watchForPlaidLinkEvents, {linkChannel}),
    fork(watchProviderLinksCreated),
    fork(openLinkWhenRequested)
  ];
}

//// SAGA FUNCTIONS ////


export function* watchProviderLinksCreated() {
  while (true) {
    try {
      let { publicToken, accounts } = yield take(ActionTypes.ProviderLinks.LinkPublicTokenWasCreated);

      // NOTE: This was for pre-loading the accounts on the UI as Pending,
      //        ... but needs a little more work.
      //
      // for (let account of accounts) {
      //   yield put(Actions.Accounts.accountWasAdded({
      //     id: account.id,
      //     linkState: 'pending',
      //     name: {
      //       short: account.name,
      //       long: account.officialName,
      //     }
      //   }));
      // }

      let graph = yield select(getGraphClient);
      let service = new AddProviderLinkService({ graph });

      let resp = yield call([service, service.addProviderLinkWithPublicToken], publicToken, { accountIds: accounts.map(a => a.id) });

      console.log('watchProviderLinksCreated :: ', resp);
    } catch (err) {
      console.error('Error in watchProviderLinksCreated :: ', err);
    }
  }
}

import { eventChannel } from 'redux-saga'
import { trackEvent } from '../../Analytics';
import { LinkEvent } from '../../plaid/Analytics';

function linkServiceChannel() {
  return eventChannel(emitter => {
    (window as any).linkService = new LinkService({
      clientName: '57771f0a0259902a3980f389',
      env: PlaidEnvironment.Sandbox,
      product: [PlaidService.Transactions],
      publicKey: '6ca95bfcd92a7b9d6a0ed8f0624481',
      webhook: 'https://hooks-local-cellflow-dev.ngrok.io/hooks/plaid',

      onSuccess: (publicToken, metadata) => emitter({type: 'success', publicToken, metadata}),
      onEvent: (eventName, metadata) => {
        let event = LinkEvent(eventName, metadata);

        if (event) {
          trackEvent(event[0], event[1]);
        }

        emitter({type: 'event', eventName, metadata})
      },
      onExit: () => emitter({type: 'exit'}),
    });

    return () => { (window as any).linkService.closeLink(null); }
  });
}

export function* watchForPlaidLinkEvents({linkChannel}) {
  while (true) {
    try {
      let { type, ...msg } = yield take(linkChannel);

      if (type === 'success') {
        yield put(Actions.ProviderLinks.linkPublicTokenWasCreated(msg.publicToken, { accounts: msg.metadata.accounts }));
      }
    } catch (err) {
      console.error('Unexpected error in watchForPlaidLinkEvents: ', err);
    }
  }
}

export function* openLinkWhenRequested() {
  while (true) {
    try {
      let action = yield take(ActionTypes.ProviderLinks.OpenLinkWasRequested);
      
      console.log('open link was requested: ', action);

      // PublicTokenFetcher;
      // getAuthToken;

      if (action.providerId) {
        /// convert accountId to publicToken...
        let authToken = yield select(getAuthToken);
        console.log('authToken :: ', authToken);
        let publicToken = yield call(PublicTokenFetcher, { token: authToken, providerId: action.providerId });
        console.log('publicToken :: ', publicToken);

        (window as any).linkService.openLink(publicToken);
      } else {
        (window as any).linkService.openLink();
      }

    } catch (err) {
      console.error('Error in openLinkWhenRequested :: ', err);
    }
  }
}


// clientName='57771f0a0259902a3980f389'
// env = { PlaidEnvironment.Sandbox }
// product = { [PlaidService.Transactions]}
// publicKey = '6ca95bfcd92a7b9d6a0ed8f0624481'
// apiVersion = 'v2'
// onSuccess = {(publicToken) => doAddProviderLink(publicToken)}
// onEvent = { handleLinkOnEvent }
// onLoad = { handleLinkOnLoad }
// onExit = { handleLinkOnExit }
// webhook = 'https://hooks-local-cellflow-dev.ngrok.io/hooks/plaid' > Add account < /PlaidLink>

// handleLinkOnSuccess = async (token: any, metadata: any) => {
//   let { onAddProviderLink } = this.props;

//   onAddProviderLink(token);
// }

// handleLinkOnEvent = (eventName: any, metadata: any) => {
//   console.log('link: user event', eventName, metadata);

//   let event = LinkEvent(eventName, metadata);

//   if (event) {
//     trackEvent(event[0], event[1]);
//   }
// }

// handleLinkOnLoad = () => {
//   trackEvent('open', {
//     category: 'plaid/link',
//   });
// }

// handleLinkOnExit = () => {
//   trackEvent('close', {
//     category: 'plaid/link',
//   });
// }