import * as React from 'react';

import { connect } from 'react-redux';

import Actions from '../store/actions';

interface IProps {
  children: ({ }) => any;
  openLink: (msg: {accountId?}) => void;
  addProviderLink: (publicToken: string) => void;
  removeProvider: (providerId: string) => void;
}

const stateToProps = state => ({});

const dispatchToProps = dispatch => ({
  openLink: ({providerId = undefined} = {}) => dispatch(Actions.ProviderLinks.openLinkWasRequested(providerId)),
  addProviderLink: (publicToken: string, metadata: any) => dispatch(Actions.ProviderLinks.linkPublicTokenWasCreated(publicToken, metadata)),
  removeProvider: (providerId: string) => dispatch(Actions.Accounts.removeProviderWasRequested(providerId))
});

class Container extends React.Component<IProps, any> {
  render() {
    let { children, ...rest } = this.props;

    return children(rest);
  }
}

export default connect( stateToProps, dispatchToProps )(Container);
