import * as React from 'react';

import { connect } from 'react-redux';
import { isEqual } from 'lodash';

import { getViewer } from '../store/reducers';

interface IProps {
  children: ({}) => any;
  viewer: any;
}

const mapStateToProps = state => {
  return {
    viewer: getViewer(state),
  }
}

const mapDispatchToProps = dispatch => {
  return {}
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(class extends React.Component<IProps, any> {
  shouldComponentUpdate(nextProps) {
    return !isEqual(this.props.viewer, nextProps.viewer);
  }

  render() {
    let { children, ...rest } = this.props;

    return children(rest);
  }
});
