import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createLogger } from 'redux-logger';
import rootSagaBuilder, { Dependencies as SagaDependencies } from './sagas';
import rootReducer from './reducers';

export type Dependencies = SagaDependencies;

//// STORE ////

export default function configureStore(initialState, deps: Dependencies) {
  const loggerMiddleware = createLogger();
  const sagaMiddleware = createSagaMiddleware();
  const store = createStore(
    rootReducer,
    composeWithDevTools(
      applyMiddleware(
        sagaMiddleware,
        loggerMiddleware
      )
    )
  );

  sagaMiddleware.run(rootSagaBuilder(deps));

  return store;
}
