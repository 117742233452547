import { IHostTransactionService, ITransaction } from '../../services/host-services/IHostTransactionService';

export class GSuiteTransactionService implements IHostTransactionService {

  async addTransaction(transaction: ITransaction): Promise<any> {
    return new Promise((res, rej) => {
      // @ts-ignore
      google.script.run
        .withSuccessHandler(_ => {
          res(_);
        })
        .withFailureHandler(_ => {
          rej(_);
        })
        .addTransaction(JSON.stringify(transaction));
    });
  }
    
}