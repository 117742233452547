import * as React from 'react';


type Props = {
};

export default class ProfilePage extends React.Component<Props, {}> {

  render() {
    return (
      <div className='pages-ProfilePage'>
        <section>
          <h1>Profile</h1>
        </section>
        
        <section>
          <h2>Subscription</h2>
        </section>
      </div>
    );
  }
}
