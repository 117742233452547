
import { ActionTypes } from '../types';

import { createReducer } from '../helpers';
import GraphClient from '../../services/GraphClient';
import * as _ from 'lodash';
// import SubscriptionClient from '../../services/SubscriptionClient';

const initialState = {
  graphClient: new GraphClient({}),
  // subscriptionClient: new SubscriptionClient({}),
};


// SELECTORS //

export const Selectors = {
  getGraphClient: state => state.graphClient,
  getSubscriptionClient: state => state.subscriptionClient,
}

// REDUCER //

export default createReducer(
  initialState,
  {
    [ActionTypes.Auth.AuthInfoWasUpdated]: (state, action) => ({
      ...state,
      graphClient: new GraphClient({ token: _.get(action, 'authInfo.accessToken', null) }),
      // subscriptionClient: new SubscriptionClient({ token: _.get(action, 'authInfo.accessToken', null) }),
    }),
  }
);