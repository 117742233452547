import * as auth0 from 'auth0-js';
import { asyncify } from '../../util';
import { IAuthInfo } from '../../auth/IAuthInfo';
import { IAuthService } from '../../auth/IAuthService';
import { IAuthStorage } from '../../auth/IAuthStorage';

type Auth0Result = {
  accessToken: string;
  idToken: string;
  expiresIn: number;
};

function isAuth0Result(obj: any): obj is Auth0Result {
  return !(
    obj.accessToken === undefined
    || obj.idToken === undefined
    || obj.expiresIn === undefined
  );
}

export class AuthService implements IAuthService {
  private auth0: auth0.WebAuth;
  private clientId: string;
  private redirectUri: string;

  constructor({ domain, clientId, redirectUri, audience }) {
    this.clientId = clientId;
    this.redirectUri = redirectUri;

    this.auth0 = new auth0.WebAuth({
      domain,
      clientID: clientId,
      redirectUri,
      audience,
      responseType: 'token id_token',
      scope: 'openid email profile',
    });
  }

  login = async () => {
    this.auth0.authorize({
      prompt: 'select_account'
    });
  }

  renewToken = async () => {
    let { auth0 } = this;

    return new Promise<Auth0Result>((res, rej) => {
      console.log('renewToken -> Starting ... ');
      auth0.checkSession({},
        (err, result) => {
          if (err) {
            console.error(
              `Could not get a new token (${err.error}).`,
              err,
            );
            rej(err);
          } else {
            if (!isAuth0Result(result)) {
              console.error('AuthResult is unexpected shape: ', result);

              rej(new Error('Unexpected AuthResult object'));
            }
            console.log('renewToken -> RESULT :', result);
            res(<Auth0Result>result);
          }
        }
      );
    }).then(async (result: Auth0Result) => {
      let authProfile = await asyncify<any>(cb => this.auth0.client.userInfo(result.accessToken, cb))();

      return {
        accessToken: result.accessToken,
        idToken: result.idToken,
        expiresAt: new Date((result.expiresIn * 1000) + new Date().getTime()),
        profile: authProfile
      };
    });
  }

  logout = async () => {
    let { auth0 } = this;

    try {
      auth0.logout({
        clientID: this.clientId,
        returnTo: this.redirectUri,
      });
    } catch (err) {
      console.error('Error logging out -> ', err);
    }
  }

  async extractAuthInfoFromLocationHash() {
    try {
      console.log('extractAuthInfoFromLocationHash: ', window.location.hash);
      let result = await asyncify<Auth0Result>(cb => this.auth0.parseHash(cb))();

      if (!result.accessToken || !result.idToken) {
        throw new Error('Error: ' + JSON.stringify(result));
      }

      let authProfile = await asyncify<any>(cb => this.auth0.client.userInfo(result.accessToken, cb))();

      return {
        accessToken: result.accessToken,
        idToken: result.idToken,
        expiresAt: new Date((result.expiresIn * 1000) + new Date().getTime()),
        profile: authProfile
      };
    } catch (err) {
      console.error('Error: ', err);

      throw err;
    }
  }
}

export class AuthStorage implements IAuthStorage {
  authInfo: IAuthInfo | null = null;

  async clearAuthInfo() {
    this.authInfo = null;
  };

  async persistAuthInfo(info: IAuthInfo | null) {
    this.authInfo = info;
  };

  async retrieveAuthInfo(): Promise<IAuthInfo | null> {
    return this.authInfo;
  }
}
