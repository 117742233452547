export const trackEvent = ( name: string, {
    category = null, 
    label = null,
    value = null
  } ) => {
  (window as any).$gtag('event', name, {
    event_category: category,
    event_label: label,
    event_value: value,
  });
}

export const trackPageView = (path: string) => {
  (window as any).$gtag('config', (window as any).$ga_property, {
    page_path: path,
  });
}

