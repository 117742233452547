import { HostType } from './hosts/HostType';
import { isOfficeEnvironment } from './office/Host';


export default () => {
  let location = window.location;

   if (location.hostname === 'app.local.cellflow.pw') {
    return new LocalConfig();
  } else if (location.hostname === 'app.cellflow.pw') {
    return new DevConfig();
  } else if (window['$cellflow'] && window['$cellflow'].environment == 'dev') {
    return new DevGSuiteConfig();
  }

  throw new Error('No configuration for host name found!');
}

class LocalConfig {
  
  hostType: HostType;
  
  constructor() {
    this.hostType = isOfficeEnvironment() ? HostType.OFFICE : HostType.WEB;
  }

  getAppConfig() {
    return {
      baseUri: 'https://app.local.cellflow.pw:21670',
    }
  }

  getApiConfig() {
    return {
      baseUri: 'https://api.local.cellflow.pw:21660',
    }
  }

  getAuthConfig() {
    return {
      domain: 'cellflow.auth0.com',
      clientId: 'N9VSLovIc14KIvdbfEyt48VqGyfmClLz',
      redirectUri: 'https://app.local.cellflow.pw:21670/auth/callback',
      audience: 'https://api.cellflow.pw',
    };
  }

  getPusherConfig() {
    return {
      key: 'ee7a3dfad5dfeb74454d',
    };
  }
};

class DevConfig {

  hostType: HostType;

  constructor() {
    this.hostType = isOfficeEnvironment() ? HostType.OFFICE : HostType.WEB;
  }

  getAppConfig() {
    return {
      baseUri: 'https://app.cellflow.pw',
    }
  }

  getApiConfig() {
    return {
      baseUri: 'https://api.cellflow.pw',
    }
  }

  getAuthConfig() {
    return {
      domain: 'cellflow.auth0.com',
      clientId: 'N9VSLovIc14KIvdbfEyt48VqGyfmClLz',
      redirectUri: 'https://app.cellflow.pw/auth/callback/',
      audience: 'https://api.cellflow.pw',
    };
  }

  getPusherConfig() {
    return {
      key: 'ee7a3dfad5dfeb74454d',
    };
  }
};

class DevGSuiteConfig {

  hostType = HostType.GSUITE;

  getAppConfig() {
    return {
      baseUri: 'https://app.cellflow.pw',
    }
  }

  getApiConfig() {
    return {
      baseUri: 'https://api.cellflow.pw',
    }
  }

  getAuthConfig() {
    return {
      domain: 'cellflow.auth0.com',
      clientId: '3kDE4Ma1V4RJhejpiDXU3pjT5591Wk69',
      authorizationUri: window['$cellflow']['authRedirect'],
      redirectUri: null,
      audience: 'https://api.cellflow.pw',
    };
  }

  getPusherConfig() {
    return {
      key: 'ee7a3dfad5dfeb74454d',
    };
  }
};