export enum PlaidEnvironment {
  Sandbox = 'sandbox',
  Development = 'development',
  Production = 'production',
}

export enum PlaidService {
  Connect = 'connect',
  Info = 'info',
  Auth = 'auth',
  Identity = 'identity',
  Income = 'income',
  Transactions = 'transactions',
}

type LinkProps = {
  apiVersion?: string;
  clientName: string;
  env: PlaidEnvironment;
  institution?: string;
  publicKey: string;
  product: PlaidService[];
  token?: string;
  selectAccount?: boolean;
  webhook?: string;
  onSuccess: ((token: any, metadata: any) => any);
  onExit?: ((error: any, metadata: any) => any);
  onLoad?: () => any;
  onEvent?: ((eventName: any, metadata: any) => any);
  onClick?: () => any;
  style?: object;
  className?: string;
};


export default class LinkService {

  private props: LinkProps;
  private linkHandler: any;

  args: Partial<LinkProps> = {
    apiVersion: 'v2',
    env: PlaidEnvironment.Sandbox,
    selectAccount: false,
  };

  constructor(props: LinkProps) {
    this.props = {
      apiVersion: 'v2',
      env: PlaidEnvironment.Sandbox,
      selectAccount: false,
      ...props
    };

    this.linkHandler = (window as any).Plaid.create({
      isEmbedded: true,

      apiVersion: this.props.apiVersion,
      clientName: this.props.clientName,
      env: this.props.env,
      key: this.props.publicKey,
      product: this.props.product,
      selectAccount: this.props.selectAccount,
      token: this.props.token,
      webhook: this.props.webhook,

      // Callbacks
      onExit: this.props.onExit,
      onLoad: this.props.onLoad,
      onEvent: this.props.onEvent,
      onSuccess: this.props.onSuccess,
    });
  }

  openLink(publicToken = null) {
    if (publicToken) {
      let link = (window as any).Plaid.create({
        isEmbedded: true,
        apiVersion: this.props.apiVersion,
        clientName: this.props.clientName,
        env: this.props.env,
        key: this.props.publicKey,
        product: this.props.product,
        selectAccount: this.props.selectAccount,
        token: publicToken,
        webhook: this.props.webhook,

        // Callbacks
        onExit: this.props.onExit,
        onLoad: this.props.onLoad,
        onEvent: this.props.onEvent,
        onSuccess: this.props.onSuccess,
      });

      link.open();
    } else {
      this.linkHandler.open();
    }
  }

  closeLink(configurationObject: any) {
    this.linkHandler.exit(configurationObject);
  }
}


// <PlaidLink
  
// clientName='57771f0a0259902a3980f389'
// env = { PlaidEnvironment.Sandbox }
// product = { [PlaidService.Transactions]}
// publicKey = '6ca95bfcd92a7b9d6a0ed8f0624481'
// apiVersion = 'v2'
// onSuccess = {(publicToken) => doAddProviderLink(publicToken)}
// onEvent = { handleLinkOnEvent }
// onLoad = { handleLinkOnLoad }
// onExit = { handleLinkOnExit }
// webhook = 'https://hooks-local-cellflow-dev.ngrok.io/hooks/plaid' > Add account < /PlaidLink>

// handleLinkOnSuccess = async (token: any, metadata: any) => {
//   let { onAddProviderLink } = this.props;

//   onAddProviderLink(token);
// }

// handleLinkOnEvent = (eventName: any, metadata: any) => {
//   console.log('link: user event', eventName, metadata);

//   let event = LinkEvent(eventName, metadata);

//   if (event) {
//     trackEvent(event[0], event[1]);
//   }
// }

// handleLinkOnLoad = () => {
//   trackEvent('open', {
//     category: 'plaid/link',
//   });
// }

// handleLinkOnExit = () => {
//   trackEvent('close', {
//     category: 'plaid/link',
//   });
// }
