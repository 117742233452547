// The structure of the redux/saga code follows a couple different
// patterns.
//
// @see https://hackernoon.com/replacing-redux-thunks-with-redux-sagas-4aa306854925
// @see https://hackernoon.com/moving-api-requests-to-redux-saga-21780f49cbc8
//


// SELECTORS //

// -> Accounts //
import accountsReducer, { Selectors as AccountsSelectors } from './accounts';
export const getAccountList = state => AccountsSelectors.getAccountList(state.accounts);

// -> Api //
import apiReducer, { Selectors as ApiSelectors } from './api';
export const getGraphClient = state => ApiSelectors.getGraphClient(state.api);
export const getSubscriptionClient = state => ApiSelectors.getSubscriptionClient(state.api);

// -> Auth //
import authReducer, { Selectors as AuthSelectors } from './auth';
export const getAuthStatus = state => AuthSelectors.getAuthStatus(state.auth);
export const getAuthToken = state => AuthSelectors.getAuthToken(state.auth);
export const getAuthExpiration = state => AuthSelectors.getAuthExpiration(state.auth);
export const getAuthProfile = state => AuthSelectors.getAuthProfile(state.auth);

// -> Feeds //
import feedsReducer, { Selectors as FeedsSelectors } from './feeds';
export const getFeedList = state => FeedsSelectors.getFeedList(state.feeds);

// -> Viewer //
import viewerReducer, { Selectors as ViewerSelectors } from './viewer';
export const getViewer = state => ViewerSelectors.getViewer(state.viewer);

// REDUCERS //

import { combineReducers } from 'redux';

const rootReducer = combineReducers({
  accounts: accountsReducer,
  api: apiReducer,
  auth: authReducer,
  feeds: feedsReducer,
  viewer: viewerReducer,
});

export default rootReducer;
