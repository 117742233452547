import GraphClient from '../../GraphClient';

type Dependencies = {
  graph: GraphClient;
}

const GQL =
  `mutation AddProviderLink($publicToken: String!, $accountIds: [String]) {
    addProviderLinkWithPublicToken(publicToken: $publicToken, accountIds: $accountIds) {
      status
    }
  }`;

export default class AddProviderLinkService {

  private graph: GraphClient;

  constructor({ graph }: Dependencies) {
    this.graph = graph;
  }


  async addProviderLinkWithPublicToken(publicToken: string, { accountIds = undefined } = {}) {
    let resp = await this.graph.doMutation(GQL, {
      publicToken,
      accountIds,
    });

    console.log('addProviderLinkWithPublicToken :: ', resp);

    return resp.data.addProviderLinkWithPublicToken.status;
  }
}