

export const isOfficeEnvironment = () => {
  let isInOffice = false;
  try {
    isInOffice = Office.context.requirements.isSetSupported("ExcelApi");
  } catch (e) {
    isInOffice = false;
  }

  return isInOffice;
}