import GraphClient from '../GraphClient';

type Dependencies = {
   graph: GraphClient;
}

const GQL = 
  `query ViewerQuery {
    viewer {
      ...on User {
        id
        profile {
          name
          email
        }
      }
    }
  }`;

export default class FetchViewer {

  private graph: GraphClient;

  constructor({ graph }: Dependencies) {
    this.graph = graph;
  }

  async fetch() {
    let resp = await this.graph.doQuery(GQL);

    return resp.data.viewer;
  }

}