import { IAuthInfo } from '../../auth/IAuthInfo';
import { IAuthService } from '../../auth/IAuthService';
import { IAuthStorage } from '../../auth/IAuthStorage';

export class AuthService implements IAuthService {
  private authorizationUri: string;

  constructor({ authorizationUri }) {
    this.authorizationUri = authorizationUri;
  }

  login = async () => {
    window.open(this.authorizationUri, '_blank');
  }

  renewToken = async () => {
    throw new Error('Not yet implemented');
  }

  logout = () => {
    throw new Error('Not yet implemented');
  }
}

export class AuthStorage implements IAuthStorage {
  
  async clearAuthInfo() {
    // this.authInfo = null;
  };

  async persistAuthInfo(info: IAuthInfo | null) {
    // this.authInfo = info;
  };

  async retrieveAuthInfo(): Promise<IAuthInfo | null> {
    // @ts-ignore
    return new Promise((res, rej) => {
      // @ts-ignore
      google.script.run
        .withSuccessHandler(_ => {
          console.log('retrieveAuthInfo() -> ', _);

          let info = JSON.parse(_);
          info.expiresAt = new Date(info.expiresAt);

          res(info);
        })
        .withFailureHandler(_ => {
          console.log('retrieveAuthInfo FAILURE -> ', _);

          rej(_);
        })
        .retrieveAuthToken();
    });
  }
}