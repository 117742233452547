import * as React from 'react';

import './AccountsPage.scss';
import ProviderLinkContainer from '../containers/ProviderLinkContainer';
import AccountListContainer from '../containers/AccountListContainer';
import { AccountList } from '../components/AccountList';
import { PageBar } from '../components/shell/PageBar';
import { IconNames } from '@blueprintjs/icons';
import HostServicesContainer from '../containers/HostServicesContainer';
import { Button, Popover, Menu, Position, H5 } from '@blueprintjs/core';
import { IHostTransactionService } from '../services/host-services/IHostTransactionService';
import MediaQuery from 'react-responsive';

type Props = {
  onAddProviderLink: (publicToken: string) => any;
};

// const ListOfAccounts = (accountList) => accountList.map((a) => <div key={a.id} className={a.linkState === 'pending' ? 'pending' : ''}>{a.name.short}</div>);

export default class AccountsPage extends React.Component<Props, {}> {

  items({ openLink }) {
    return [
      {
        key: 'addAccount',
        name: 'Add',
        cacheKey: 'myCacheKey',
        iconProps: {
          iconName: 'Add'
        },
        ariaLabel: 'Add. Use left and right arrow keys to navigate',
        ['data-automation-id']: 'addAccountMenu',
        onClick: () => openLink()
      },
      {
        key: 'edit',
        name: 'Edit',
        iconProps: {
          iconName: 'Edit'
        },
        onClick: () => openLink()
      }
    ];
  }

  render() {
    return (
      <ProviderLinkContainer>
        {({ openLink, removeProvider }) => (
          <div className='pages-AccountsPage'>
            <PageBar title='Accounts'>
              <Button minimal={true}
                icon={IconNames.ADD}
                onClick={() => openLink()}
              ></Button>

              <HostServicesContainer>
                {({ transactionService }: { transactionService: IHostTransactionService }) => (
                  <Popover
                    content={
                      <Menu>
                        <Menu.Item
                          icon={IconNames.ADD}
                          text="Add fake transaction"
                          onClick={() => transactionService.addTransaction({ id: '12345', amount: 150.00, description: 'Paggliaci Pizza', transactionDate: new Date() })}
                        />
                      </Menu>
                    }
                    position={Position.BOTTOM_RIGHT}>

                    <Button minimal={true}
                      icon={IconNames.COG}></Button>
                  </Popover>
                )}
              </HostServicesContainer>
            </PageBar>

            <AccountListContainer>
              {({ accountList }) => (
                <section>
                  <MediaQuery minWidth={600}>
                    {(isLargeDisplay) => {
                      let style = {
                        display: 'grid',
                        gridTemplateColumns: '1fr 10px 1fr',
                        gridTemplateRows: '1fr',
                        gridTemplateAreas: (isLargeDisplay ? '"accounts buffer misc"' : '"accounts accounts accounts"'),
                      };

                      return (
                        <div style={style}>
                          <AccountList
                            htmlProps={{
                              style: {
                                gridArea: 'accounts',
                              }
                            }}
                            accounts={accountList}
                            onAddAccountsClicked={openLink}
                            onRemoveProvider={(providerId) => removeProvider(providerId)}
                            onEditProvider={(providerId) => openLink({ providerId })} />

                          <div style={{ 
                            display: (isLargeDisplay ? 'block' : 'none'),
                            gridArea: 'misc',
                            marginTop: 15,
                            }}>
                              <section>
                              <H5>Something else</H5>

                              <p>
                                Placeholder. Placeholder. Placeholder. Placeholder. Placeholder. Placeholder. 
                                Placeholder. Placeholder. Placeholder. Placeholder. Placeholder. Placeholder.
                              </p>
                              <p>
                                Placeholder. Placeholder. Placeholder. Placeholder. 
                              </p>

                              </section>                           
                            </div>
                        </div>
                      );
                    }}
                  </MediaQuery>
                </section>
              )}
            </AccountListContainer>
          </div>
        )}
      </ProviderLinkContainer>
    );
  }
}