

// import { ActionTypes } from '../types';
import { createReducer } from '../helpers';

export enum FeedTypes {
  Transactions = 'TRANSACTIONS',
  Balances = 'BALANCES',
};

export type TFeedSource = {
  accountId: string;
  feedType: FeedTypes;
};

export type TFeedTarget = {
  sheetName: string;
};

export type TFeed = {
  sources: TFeedSource[],
  target: TFeedTarget
};

type IState = {
  feedList: TFeed[],
};

const initialState: IState = {
  feedList: [
    {
      sources: [
        {
          accountId: 'account:JrTkvmt6dYo5J6Bw',
          feedType: FeedTypes.Transactions
        }
      ],
      target: {
        sheetName: 'Transactions'
      }
    }

  ],
};


// SELECTORS //

export const Selectors = {
  getFeedList: state => state.feedList,
}

// REDUCER //

export default createReducer(
  initialState,
  {

  }
);