import * as React from 'react';
import {
  HashRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';

import PrivateRoute from './PrivateRoute';
import Dashboard from '../components/Dashboard';
import LoginPage from '../pages/LoginPage';
import AccountsPage from '../pages/AccountsPage';
import ProfilePage from '../pages/ProfilePage';
import FeedsPage from '../pages/FeedsPage';
import ErrorBoundary from './ErrorBoundary';

import './App.scss';

import AuthInfoContainer from '../containers/AuthInfoContainer';
import OnboardingApp from '../onboarding/App';
import DialogRoutes from '../pages/dialogs/Routes';
import Api from '../services/Api';
import AppShell from './shell/AppShell';
import DeveloperInfoPage from '../pages/DeveloperInfoPage';

const FeedsPageRenderer = (props) => (
  <FeedsPage />
);

const ProfilePageRenderer = (props) => (
  <ProfilePage />
);

const DashboardRenderer = (props) => (
  <AuthInfoContainer>
    {({ authToken, doLogout }) => {

      let api = new Api({ token: authToken });

      return (<Dashboard onLogoutClicked={doLogout} api={api} {...props} />);
    }}
  </AuthInfoContainer>
);

const DeveloperInfoRenderer = (props) => (
  <AuthInfoContainer>
    { ({authToken, doLogout}) => {
      
      let api = new Api({token: authToken});

      return (<DeveloperInfoPage onLogoutClicked={doLogout} api={api} {...props} />); 
    }}
  </AuthInfoContainer>
);

const AccountsPageRenderer = (props) => {
  const addLinkHandler = (token) => console.log('Got addLinkHandler w/ Token: ', token);

  return (<AccountsPage onAddProviderLink={addLinkHandler} />);
};


// App interfaces

interface IProps {
  onApplicationMount: () => void;
}
interface IState {
}

export default class App extends React.Component<IProps, IState> {
  
  componentDidMount() {
    this.props.onApplicationMount();
  }

  render() {
    return (
      <div className='App'>
        <ErrorBoundary prefix='Routes'>
          <AuthInfoContainer>
            {({ authStatus, authToken }) => {
              return (
                <AppShell>
                  <Router>
                    <Switch>
                      <Route exact
                            path='/auth/login'
                            component={LoginPage} />
                      <PrivateRoute exact
                                    path='/developer'
                                    authStatus={authStatus}
                                    render={DeveloperInfoRenderer} />
                      <PrivateRoute path='/dialogs'
                                    authStatus={authStatus}
                                    component={DialogRoutes} />
                      <PrivateRoute exact
                                    path='/accounts'
                                    authStatus={authStatus}
                                    render={AccountsPageRenderer} />
                      <PrivateRoute exact
                                    path='/feeds'
                                    authStatus={authStatus}
                                    render={FeedsPageRenderer} />
                      <PrivateRoute exact
                                    path='/profile'
                                    authStatus={authStatus}
                                    render={ProfilePageRenderer} />
                      <PrivateRoute path='/onboard'
                                    authStatus={authStatus}
                                    component={OnboardingApp} />
                      <PrivateRoute exact
                                    path='/'
                                    authStatus={authStatus}
                                    render={DashboardRenderer} />

                    </Switch>
                  </Router>
                </AppShell>
              );
            }}
          </AuthInfoContainer>
        </ErrorBoundary>
      </div>
    );
  }
}
