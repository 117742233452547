import GraphClient from '../../GraphClient';
import { get } from 'lodash/fp';

type Dependencies = {
  graph: GraphClient;
}

const GQL =
  `query PublicTokenQuery($providerId: String!) {
    viewer {
      ...on User {
        providers(id: $providerId) {
          providerLink {
            publicToken {
              token
            }
          }
        }
      }
    }
  }`;

const tokenGetter = get('viewer.providers[0].providerLink.publicToken.token');

export default class FetchPublicToken {

  private graph: GraphClient;

  constructor({ graph }: Dependencies) {
    this.graph = graph;
  }

  async fetchToken(providerId) {
    let resp = await this.graph.doQuery(GQL, {providerId});

    return tokenGetter(resp.data);
  }

}