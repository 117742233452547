import * as React from 'react';

import { Navbar, Alignment, Button, Classes, Popover, Menu, Position } from '@blueprintjs/core';

import './AppShell.scss';
import { IconNames } from '@blueprintjs/icons';
import MediaQuery from 'react-responsive';

export class AppShell extends React.Component<{}, {}> {

  private navigateTo(route: string) {
    window.location.hash = route;
  }

  render() {
    return (
      <MediaQuery minWidth={600}>
        {(isLargeDisplay) => {

          if (isLargeDisplay) {
            return (
              <div className='AppShell'>
                {this.props.children}
              </div>
            );
          } else {
            return (
              <div className='AppShell'>
                <Navbar className={Classes.DARK} style={{ marginTop: -8, marginLeft: -8, marginRight: -8, width: 'calc(100% + 16px)' }}>
                  <Navbar.Group align={Alignment.LEFT}>
                    <Popover
                      autoFocus={false}
                      content={
                        <Menu>
                          <Menu.Item 
                            icon={IconNames.DASHBOARD} 
                            text="Dashboard"
                            onClick={() => this.navigateTo('/')} />
                          <Menu.Divider></Menu.Divider>
                          <Menu.Item 
                            icon={IconNames.BANK_ACCOUNT} 
                            text="Accounts"
                            onClick={() => this.navigateTo('/accounts')} />
                          <Menu.Item 
                            icon={IconNames.USER} 
                            text="Profile"
                            onClick={() => this.navigateTo('/profile')} />
                          <Menu.Divider></Menu.Divider>
                          <Menu.Item
                            icon={IconNames.SETTINGS}
                            text="Advanced ...">

                            <Menu.Item text="Developer information" onClick={() => this.navigateTo('/developer')} />

                          </Menu.Item>
                        </Menu>
                      }
                      position={Position.BOTTOM_LEFT}
                      minimal={true}
                    >

                      <Button minimal={true} icon={IconNames.MENU} />
                    </Popover>
                  </Navbar.Group>
                  <Navbar.Group id='pageBarContainer'>

                  </Navbar.Group>
                </Navbar>

                {this.props.children}
              </div>
            );
          }
        }}
      </MediaQuery>
    );
  }
}

export default AppShell;