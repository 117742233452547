import * as React from 'react';

import { connect } from 'react-redux';
import { isEqual } from 'lodash';

import { getAccountList } from '../store/reducers';
import { Provider } from '../services/api/FetchAccounts';

interface IProps {
  children: ({ }) => any;
  accountList: Provider[];
}

const stateToProps = state => {
  return {
    accountList: getAccountList(state),
  }
}

const dispatchToProps = dispatch => ({
});

class Container extends React.Component<IProps, any> {
  shouldComponentUpdate(nextProps) {
    return (!isEqual(this.props.accountList, nextProps.accountList));
  }


  render() {
    let { children, ...rest } = this.props;

    return children(rest);
  }
}

export default connect(stateToProps, dispatchToProps)(Container);
