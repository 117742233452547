
import { take, select, fork, put, call } from 'redux-saga/effects';
import { ActionTypes } from '../types';
import { Accounts as AccountActions, Viewer as ViewerActions } from '../actions';
import { getAuthToken } from '../reducers';
import GraphClient from '../../services/GraphClient';
import FetchAccounts from '../../services/api/FetchAccounts';
import RemoveProvider from '../../services/api/RemoveProvider';

const Actions = {
  Accounts: AccountActions,
  Viewer: ViewerActions,
};

const AccountsFetcher = 
  async ({ token }) => new FetchAccounts({ graph: new GraphClient({ token }) }).fetch();

const ProviderRemover =
  async ({ token, providerId }) => new RemoveProvider({ graph: new GraphClient({ token }) }).removeProvider(providerId);

export type Dependencies = {
  fetchAccounts?: (opts: { token: string }) => Promise<any>;
  removeProvider?: (opts: { token: string, providerId: string }) => Promise<any>;
};

// Dependency Imports //

export default function ({
  fetchAccounts = AccountsFetcher,
  removeProvider = ProviderRemover,

}: Dependencies = {}) {
  return [
    // fork(watchForAccountsChangedEvent),
    fork(updateAccounts, { fetchAccounts }),
    fork(removeProviderWhenRequested, { removeProvider }),
  ];
}

//// SAGA FUNCTIONS ////

export function* removeProviderWhenRequested({ removeProvider }) {
  while (true) {
    try {
      let { providerId } = yield take([ActionTypes.Accounts.ProviderRemovalWasRequested]);
      
      let authToken = yield select(getAuthToken);
      let accounts = yield call(removeProvider, { token: authToken, providerId });

      yield put(Actions.Accounts.accountListWasUpdated(accounts));
    } catch (err) {
      console.error('Error updating accounts: ', err);
    }
  }
}

export function* updateAccounts({ fetchAccounts }) {
  while (true) {
    try {
      yield take([ActionTypes.Viewer.ViewerWasUpdated, ActionTypes.Accounts.AccountListUpdateWasRequested]);
      let authToken = yield select(getAuthToken);

      let accounts = yield call(fetchAccounts, { token: authToken });
    
      yield put(Actions.Accounts.accountListWasUpdated(accounts));    
    } catch (err) {
      console.error('Error updating accounts: ', err);
    }
  }
}
